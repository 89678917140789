.heart {
    transform: scale(2);
    z-index: 1;
}

.spray {
    z-index: 2;
    transform: scale(1.3);
    position: absolute;
    animation: infinity 2s linear infinite;
}

@keyframes infinity {
    0% {
        top: 30%;
        left: -150%;
    }

    50% {
        top: 50%;
        left: 0%;
    }

    100% {
        top: 30%;
        left: -150%;
    }
}