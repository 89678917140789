.contentContainer {
    font-family: 'Quicksand', sans-serif;
    height: 86vh;
    margin: 0 auto;
    padding: 30px 50px;
    background-color: white;
    clear: both;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 15px 20px rgba(0, 0, 0, .2);
    text-align: center;
}

::-webkit-scrollbar {
    width: 0px;
}

.contentContainer header {
    font-size: 30px;
    font-weight: bold;
    color: #1D85E8;
    clear: both;
}

.mContent {
    height: 80%;
    margin: 10px 0;
    text-align: left;
    overflow-y: auto;
    background-color: white;
}

.boldContent {
    font-size: 14px;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
    color: black
}

.Content {
    font-size: 14px;
    font-weight: 350;
    color: black
}

.bolderContent {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 2%;
    color: rgb(154, 158, 157);
}


/* Profile cropper */
.makeStyles-title-77 {
    margin: 0 !important;
    z-index: 3;
    background: white;
}

.MuiPaper-root.MuiDialog-paper.makeStyles-paper-76 {
    position: relative !important;
}

.makeStyles-buttonalignRight-83 {
    padding: 10px 30px;
    margin: 0 !important;
    display: inline-block;
    text-align: right;
    background: white;
    z-index: 5;
}


/* // Loader  */

.loader {
    display: flex;
    flex-direction: row;
}

.loader div {
    position: relative;
    width: 40px;
    height: 200px;
    margin: 10px;
    overflow: hidden;
    border-radius: 50px;
    border: 2px solid #edf1f4;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), #edf1f4);
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1),
        -15px -15px 20px #fff,
        inset -5px -5px 5px rgba(255, 255, 255, 0.5),
        inset 5px 5px 5px rgba(0, 0, 0, 0.05);
}

.loader div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 50px;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1),
        -15px -15px 20px #fff,
        inset -5px -5px 5px rgba(255, 255, 255, 0.5),
        inset 5px 5px 5px rgba(0, 0, 0, 0.05);
}

.loader div::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    z-index: 10;
    border-radius: 50%;
    background: #fff;
    box-shadow: inset -5px -5px 5px rgba(0, 0, 0, 0.2),
        0 420px 0 400px #2196f3;
    transform: translateY(160px);
    animation: animate 2.5s ease-in-out infinite;
    animation-delay: calc(-0.5s * var(--i));
}

@keyframes animate {
    0% {
        transform: translateY(160px);
        filter: hue-rotate(180deg);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(160px);
        filter: hue-rotate(0deg);
    }
}

/* // tagsTable */
.tagsTable td {
    padding: 10px;
}

/* // Editor Icons  */
.ButtonWrap__root___1EO_R {
    display: inline-block;
    position: relative;
    z-index: 10;
    margin: 2px;
    border: none;
    background: white;
}

.ButtonGroup__root___3lEAn>div>button {
    margin-right: 0;
    border-radius: 0;
    /* background: white; */
    border: none;
}

.ButtonGroup__root___3lEAn>div>button>span {
    opacity: 0.5;
}

.ButtonGroup__root___3lEAn>div>button>span:hover {
    opacity: 1;
}

.EditorToolbar__root___3_Aqz {
    width: 100%;
    max-width: 300px;
    z-index: 10;
    background-color: #fff;
    position: absolute;
    bottom: 1px !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}

.EditorToolbar__root___3_Aqz .ButtonGroup__root___3lEAn {
    margin: 0 6px !important;
}

.RichTextEditor__editor___1QqIU {
    min-height: 120px;
    font-weight: 300;
}

.RichTextEditor__editor___1QqIU a {
    color: #1D85E8;
    cursor: pointer;
}

.RichTextEditor__editor___1QqIU a:hover {
    color: #074a8a;
}

.DraftEditor-editorContainer {
    font-family: sans-serif;
    line-height: 1 !important;
}

.ImageSpan__root___RoAqL {
    width: 300px !important;
    height: 220px !important;
    background-size: contain !important;
    vertical-align: unset !important;
    line-height: 1 !important;
    font-size: 16px !important;
    letter-spacing: 2px !important;
}

.commentText img {
    width: 300px !important;
    height: 220px !important;
    object-fit: contain !important;
}

.commentText a {
    color: #1D85E8;
    cursor: pointer;
}

.commentText a:hover {
    color: #074a8a;
}

.InputPopover__checkOption___32S87 {
    display: none;
}

.notranslate.public-DraftEditor-content {
    min-height: 100px;
}


.folder {
    /* width: 36px; */
    height: 50px;
    background: #FFC258;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    min-width: 36px;
    max-width: max-content;
}

.folder:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 14px 14px 0;
    border-style: solid;
    border-color: #ff9900 #EBEEF3;
}


.inActive_folder {
    /* width: 36px; */
    height: 50px;
    background: rgb(200, 200, 200);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    min-width: 36px;
    max-width: max-content;
}

.inActive_folder:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 14px 14px 0;
    border-style: solid;
    border-color: rgb(150, 150, 150) #EBEEF3;
}

.MuiAccordion-root:before {
    height: 0px !important;
}


.DraftEditor-editorContainer a {
    color: #1D85E8;
    cursor: pointer;
}

.DraftEditor-editorContainer a:hover {
    color: #0058ab;
}

.rdw-link-decorator-icon {
    display: none;
}

.rdw-image-alignment.rdw-image-center {
    justify-content: start;
}

figure {
    max-width: max-content;
}
