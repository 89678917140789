@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  /* font-family: 'Poppins' ,'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif; */
}

.scroll-hidden::-webkit-scrollbar {
  display: none
}

body::-webkit-scrollbar {
  display: none
}

/* just for custom scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #2d3748;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #333;
}

::-webkit-scrollbar-thumb:active {
  background: #333;
}

::-webkit-scrollbar-track {
  background: rgba(143, 143, 143, 0.3);
  border: 0px none #333;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: rgba(143, 143, 143, 0.3);
}

::-webkit-scrollbar-track:active {
  background: rgba(143, 143, 143, 0.3);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.max-w-8xl {
  /* max-width: 100rem; */
}

section {
  min-height: 100vh;
}


.bg-red {
  background-color: #FF8082;
}

.bg-green {
  background-color: #2CC8A7;
}

.bg-blue {
  background-color: #1C85E8;
}

.bg-blue:disabled {
  background-color: darkgray;
}

.bg-yellow {
  background-color: #FFC258;
}

.bg-navy {
  background-color: #092C4C;
  ;
}

.bg-brown {
  background-color: #8B383E;
}

.bg-pink {
  background-color: #D10071;
}

.bg-purple {
  background-color: #7E0093;
}

.popup-bg {
  /* background-color: #EBEEF3; */
  background-color: #F6FAFD;
}

.popup-bg-focus {
  background-color: #D8E3E7;
}

.text-red {
  color: #FF8082;
}

.text-green {
  color: #2CC8A7;
}

.text-blue {
  color: #1C85E8;
}

.text-yellow {
  color: #FFC258;
}

.text-navy {
  color: #092C4C;
  ;
}

.text-brown {
  color: #8B383E;
}

.text-pink {
  color: #D10071;
}

.text-purple {
  color: #7E0093;
}

.captchaContainer {
  display: flex;
  width: 280px;
}

.pre-alpha {
  font-size: 10px;
  background: -webkit-linear-gradient(45deg, red, blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.transition-y {
  transform: translateY('100%');
  animation: translateYAnimation 0.3s ease-in-out;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.transition-loader {
  /* transform: translateX('-100%'); */
  animation: translateLoader 5s ease-in-out;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.hover-white {
  color: #fff;
}

.hover-white:hover {
  color: #fff;
  text-decoration: none;
}

@keyframes translateYAnimation {
  0% {
    opacity: 0.5;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes translateLoader {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}


@layer {
  .inputBx {
    @apply flex-grow w-full h-12 px-4 my-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm;
  }

}