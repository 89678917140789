.projectContainer {
    width: 95%;
    margin-top: -20px;
    margin-left: -10px;
    height: 70vh;
    /* background-color: chartreuse; */
}
.projectContainer .top{
    width: 100%;
}

.projectContainer .listView {
    overflow-y: auto;
    height: 50vh;
}

.gridMain{
    padding: 2%; 
}

.input{
    height: 90%;
    width: 90%;
}

.projectContainer .icons {
    width: unset;
    margin-top: -78px;
    z-index: 100;
    position: relative;
    float: right;
    cursor: pointer;
}

.selected-icon{
background-color: #FE8083;
box-shadow: 2px 2px 4px #888888;
border-radius: 4px;
}

.unselected-icon{
    background-color:#D0D0D0;
    border-radius: 4px;
}

#icon{
    margin-right: 5px;
    margin-top: 60px;
}
.search{
    height: 50px;
    width: 405px;
    float: left;
    margin: 30px;
    top: 25px;
    border-radius: 5px;
}

.search .MuiInputBase-root {
    width: 100%;
}

.projectSortContainer{
   margin-top: 1000px;
   margin-right:50px ;
   margin-left: 0%;
}

.projectContainer .addproject{
    height: 55%;
    width: 100%;
    top: 25px;
    border-radius: 5px;
    font-size: 16px;
    float: right;
    text-transform: capitalize;
}
.gridMain{
    padding: inherit !important;
}

#card-icon{
    margin-top: 60px;
}
@media screen and (min-width: 1250px) and (max-width: 1600px) {
    .search{
        height: 35px;
        width: 100%;
        float: left;
        border-radius: 5px;
    }
    .search .MuiOutlinedInput-inputAdornedStart {
        font-size: 13px;
    }
    .input {
        font-size: 14px;
    }
    .projectContainer .addproject{
        height: 35px;
        width: 200px;
        top: 25px;
        border-radius: 5px;
        font-size: 0.05rem;
        /* float: right; */
        text-transform: capitalize;
    }
    .addproject .MuiButton-label {
        font-size: 14px;
    }
}