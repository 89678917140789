

.faqcontainer {
    width: 100vw;
    height: 100vh;
    background-color: #FE8083;
    display: grid;
    position: fixed;
}

.mainquestions{
    height:calc(120vh - 350px);
    overflow-y: scroll;
    padding-bottom: 12%;
}

.faqcontainer .main-container {
    width: 100%;
    height: 100%;
    background-color: #FE8083;
    display: grid;
    grid-template-rows: 15% 15% 70%;
    grid-template-columns: 0.7fr 1.3fr;
}

.plogo {
    height: 12vh;
    width: 12vh;
    float: left;
    margin: 3vh;
}

.face {
    float: right;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    margin-top: 20px;
    margin-right: 22px;
}

.header {
    grid-column: 1/-1;
}

.questionSymbol {
    width: 300px;
    height: 450px;
    margin-top: 10%;
    margin-left: 8%;
    /* transform: rotate(5deg) */
}

.top-container {
    /* grid-column: 1/-1; */
    margin-top: 2%;
    margin-left: -2%;
}

.question {
    font-size: 72px;
    text-align: left;
    /* margin-left: -12%; */
    font-weight: bold;
    color: rgb(9, 10, 10);
    letter-spacing: 0.05em;
}

.searchBox {
    position: relative;
}

.inputbox {
    width: 80%;
    padding: 5px;
    border-radius: 4px;
    border: none;
    text-align: left;
    padding-left: 4.4%;
    margin-left: -9%;
    color: rgb(167, 164, 164);
    padding-top: 5px !important;
    padding-bottom: 10px !important;
}

.inputSearch {
    position: absolute;
    margin-left: -8%;
    margin-top: 5px;
}

.closeIcon {
    margin-right: 13%;
    float: right;
    cursor: pointer;
}

.addIcon {
    margin-right: 12.5%;
    float: right;
    cursor: pointer;
}

.addcontent {
    padding: 2%;
    text-align: left;
    margin-left: 4%;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

hr {
    margin-right: 13%;
    margin-left: 4%;
    text-align: left;
    color: rgb(117, 113, 113);
}

.repo {
    margin: 2%;
    text-align: left;
    margin-left: 6%;
    font-size: 18px;
    font-weight: bold;
    color:white;
}

.content {
    margin-bottom: 3%;
    text-align: left;
    margin-left: 6%;
    margin-right: 18%;
    flex-wrap: wrap;
    font-size: 14px;
    text-align: justify;
    text-justify: inter-word;
    color:white;
}

@media screen and (min-width: 153px) and (max-width: 320px), (min-width: 320px) and (max-width: 480px), (min-width:480px) and (max-width: 600px), (min-width:600px) and (max-width: 768px), (min-width:768px) and (max-width: 1072px) {
    .main-container {
        display: block;
    }
    .header {
        display: flow-root;
    }
}